const trackScreen = require('./src/services/analytics').trackScreen;
require('./src/layouts/index.css');

const React = require('react');
const { AuthProvider } = require('./src/context/AuthContext');

// eslint-disable-next-line react/prop-types
exports.wrapRootElement = ({ element }) => (
  <AuthProvider>{element}</AuthProvider>
);

exports.onRouteUpdate = (state) => {
  trackScreen(state.location.pathname);
};
